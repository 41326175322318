import { useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Grid, Box, Button, Typography, TextField as MuiTextField, Checkbox as MuiCheckbox } from '@mui/material'
import { API } from './../../tools'
import { CountrySelect } from './../../components/store/'
import { StateSelect } from './../../components/store/'
import { Validators } from './../../components/forms'
import { CircularProgress } from './../../components/base'
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatcher, TYPES } from './../../store/dispatcher'
const rowSX = {
    display: 'flex',
    gap: 1,
    width: '100%',
    alignItems: 'center', 
    justifyContent: 'space-between',
    position: 'relative'
}

let initialFormData = {
    cardNumber: '',
    email: 'aconn4224@gmail.com',
    cvv: '',
    name: '',
    expiration: '',
    firstName: 'Adam',
    middleInitial: 'M',
    lastName: 'Conn', 
    address1: '128 fake street',
    address2: '',
    city: 'fake', 
    state: 'california',
    zip: '92101',
    country: { "text": "United States", "value": "US" },
    address1_billing: '',
    address2_billing: '',
    city_billing: '', 
    state_billing: '',
    zip_billing: '',
    country_billing: { "text": "United States", "value": "US" },
    billingSameAsShipping: true, 
    saveInformation: false
}

const TextField = props => {
    const {sx={}, notRequired, ...other} = props 
    let required = notRequired ? false : true

    const newSX = {
        m: 1,
        ...sx
    }
    if(required) {
        return <MuiTextField required fullWidth size="small" sx={newSX} {...other} />
    } else {
        return <MuiTextField fullWidth size="small" sx={newSX} {...other} />
    }
}
const Error = ({msg, sx={}}) => {
    if(!msg) { return <></> }
    return <Box 
        component="span" 
        sx={{
            color: theme => theme.palette.error.main,
            position: 'absolute',
            left: theme => theme.spacing(1), 
            bottom: '-.9rem',
            fontSize: '.8rem',
            ...sx
        }}
    >
            {msg}
    </Box> 
}
const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        backgroundColor: 'white',
        padding: '10px 10px'
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

const Checkbox = MuiCheckbox
export const PaymentForm = () => {
    const [formData, setFormData] = useState(initialFormData)
    const dispatcher = useDispatcher()
    const stripe = useStripe()
    const elements = useElements()
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState('');
    const [errors, setErrors] = useState({})

    const handleChange = (evt) => {
        const {name, value} = evt.target 
        
        setFormData({
            ...formData, 
            [name]: value
        })
    }
    const handleToggle = evt => {
        const {name} = evt.target
        setFormData({
            ...formData,
            [name]: evt.target.checked
        })
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors).length != 0) {
            return
        }
        setProcessing(true);
        
        // step 1 process the data
        // .  set the billing/shipping if necessary 
        // .  set the name if necessary
        // save data to server so we can send emails, and have shipping address. 
        const v = Object.assign({}, formData)
        v.state = v.state.value 
        v.country = v.country.value
        v.name = formData.name ? formData.name : `${formData.firstName} ${formData.middleInitial} ${formData.lastName}`
        v.address1_billing = v.billingSameAsShipping ? v.address1 : v.address1_billing
        v.address2_billing = v.billingSameAsShipping ? v.address2 : v.address2_billing
        v.city_billing = v.billingSameAsShipping ? v.city : v.city_billing
        v.zip_billing = v.billingSameAsShipping ? v.zip : v.zip_billing
        v.state_billing = v.billingSameAsShipping ? v.state : v.state_billing.value
        v.country_billing = v.billingSameAsShipping ? v.country: v.country_billing.value
            // address1_billing: '',
            // address2_billing: '',
            // city_billing: '', 
            // state_billing: '',
            // zip_billing: '',
            // country_billing: { "text": "United States", "value": "US" },
            // billingSameAsShipping: true, 

        // const save_cart_info = await API.store.add_cart_info()
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: v.name,
              email: v.email,
              address: {
                line1: v.address1_billing,
                line2: v.address2_billing,
                city: v.city_billing, 
                state: v.state_billing, 
                postal_code: v.zip_billing, 
                country: v.country_billing
              }
            },
          },
          shipping: {
            name: v.name,
            address: {
                line1: v.address1,
                line2: v.address2,
                city: v.city, 
                state: v.state, 
                postal_code: v.zip, 
                country: v.country
            }
          }
        });
    
        if (payload.error) {

          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
        } else {
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          dispatcher(TYPES.STORE.UPDATE_CART, {cart: {}})

        }
      };
    const validate = () => {
        let errors = {};

        // required fields
        errors.firstName = Validators.required(formData.firstName);
        errors.lastName = Validators.required(formData.lastName)
        errors.address1 = Validators.required(formData.address1)
        errors.city = Validators.required(formData.city)
        errors.state = Validators.required(formData.state)
        errors.zip = Validators.required(formData.zip)
        if(!formData.billingSameAsShipping) {
            errors.address1_billing = Validators.required(formData.address1_billing)
            errors.city_billing = Validators.required(formData.city_billing)
            errors.state_billing = Validators.required(formData.state_billing)
            errors.zip_billing = Validators.required(formData.zip_billing)
        }

        errors.email = Validators.required(formData.email)
        errors.email = !errors.email ? Validators.email(formData.email) : errors.email
        // errors.password = Validators.minLength(values.password, 6);

        // Remove any empty error messages
        Object.keys(errors).forEach(
            (key) => !errors[key] && delete errors[key]
        );
        return errors;
    };

    useEffect(() => {
        const anon = async () => {
            const data = await API.cart.create_payment_intent()
            
            setClientSecret(data.clientSecret)
        }

        anon()
    }, []);

    return <Box sx={{
        maxWidth: 800,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center',
        p: 2,
        gap: 3
    }}>

        <Box sx={{textAlign: 'left', width: '100%'}}><Typography>Customer Information</Typography></Box>
        <Box sx={rowSX}>
            <Box sx={rowSX}>
                <TextField name={"firstName"}  label="First Name" value={formData.firstName} onChange={evt => handleChange(evt)}></TextField>
                <Error msg={errors.firstName}/>
            </Box>
            <Box sx={{width: 50}}>
                <TextField name={"middleInitial"}  label="MI" value={formData.middleInitial} onChange={evt => handleChange(evt)}></TextField>
            </Box>
            <Box sx={rowSX}>
                <TextField name={"lastName"} label="Last Name" value={formData.lastName} onChange={evt => handleChange(evt)}></TextField>
                <Error msg={errors.lastName}/>
            </Box>
        </Box>
        <Box sx={rowSX}>
                <TextField name={"name"}  label="Name as-is on Card (Adjust if different)" value={formData.name} onChange={evt => handleChange(evt)} placeholder={`${formData.firstName } ${formData.middleInitial} ${formData.lastName}`}></TextField>
                <Error msg={errors.name}/>
        </Box>
        <Box sx={rowSX}>
            <TextField  name={'address1'} label={'Address Line 1'} placeholder="Street name and number" value={formData.address1} onChange={evt => handleChange(evt)}></TextField>
            <Error msg={errors.address1}/>
        </Box>
        <Box sx={rowSX}>
            <TextField notRequired={true} name={'address2'} label={'Address Line 2'} placeholder="Apt, Suite, Unit, etc. (Optional)" value={formData.address2} onChange={handleChange}></TextField>
        </Box>
        <Box sx={rowSX}>
            <Box sx={rowSX}>
                <TextField name={"city"}  label="City" value={formData.city} onChange={evt => handleChange(evt)}></TextField>
                <Error msg={errors.city}/>
            </Box>
            <Box sx={rowSX}>
                <StateSelect name="state" label="State" value={formData.state} onChange={handleChange}></StateSelect>
                <Error msg={errors.state} sx={{bottom: '-1.3rem'}}/>
            </Box>
        </Box>
        <Box sx={rowSX}>
            <Box sx={rowSX}>
                <TextField name={"zip"}  label="Zip/Postal Code" value={formData.zip} onChange={evt => handleChange(evt)}></TextField>
                <Error msg={errors.zip}/>
            </Box>
            <Box sx={rowSX}>
                <CountrySelect name="country" label="Country" onChange={handleChange} value={formData.country.value}/>
            </Box>
        </Box>
        <Box sx={rowSX}>
            <TextField name={"email"}  label="Email" value={formData.email} onChange={evt => handleChange(evt)}></TextField>
            <Error msg={errors.email}></Error>
        </Box>
        <Box sx={{width: '100%'}}>
            <Typography sx={{width: '100%', display: 'block', textAlign: 'left', mt: 3}}>Billing Information</Typography>
        </Box>
        <Box sx={rowSX}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: .5}}>
                <Checkbox 
                    checked={formData.billingSameAsShipping} 
                    name="billingSameAsShipping" 
                    onChange={evt => handleToggle(evt)}
                ></Checkbox>
                <Typography variant="small">Is the billing address the same as the shipping address?</Typography>
            </Box>
        </Box>
        { !formData.billingSameAsShipping && <>
            <Box sx={rowSX}>
                <TextField  name={'address1_billing'} label={'Address Line 1'} placeholder="Street name and number" value={formData.address1_billing} onChange={evt => handleChange(evt)}></TextField>
                <Error msg={errors.address1_billing}></Error>
            </Box>
            <Box sx={rowSX}>
                <TextField notRequired={true} name={'address2_billing'} label={'Address Line 2'} placeholder="Apt, Suite, Unit, etc. (Optional)" value={formData.address2_billing} onChange={handleChange}></TextField>
            </Box>
            <Box sx={rowSX}>
                <Box sx={rowSX}>
                    <TextField name={"city_billing"}  label="City" value={formData.city_billing} onChange={evt => handleChange(evt)}></TextField>
                    <Error msg={errors.city_billing}></Error>
                </Box>
                <Box sx={rowSX}>
                    <TextField name={"state_billing"} label="State" value={formData.state_billing} onChange={evt => handleChange(evt)}></TextField>
                    <Error msg={errors.state_billing}></Error>
                </Box>
            </Box>
            <Box sx={rowSX}>
                <Box sx={rowSX}>
                    <TextField name={"zip_billing"}  label="Zip/Postal Code" value={formData.zip_billing} onChange={evt => handleChange(evt)}></TextField>
                    <Error msg={errors.zip_billing}></Error>
                </Box>
                <Box sx={rowSX}>
                    <CountrySelect name="country_billing" label="Country" onChange={handleChange} value={formData.country_billing.value}/>
                </Box>
            </Box>

        </> }
        <Box sx={{width: {lg: '100%', sm: '100%'}, position: 'relative', alignSelf: 'start'}}>
            <Box sx={{background: 'white', p: 1, borderRadius: 1}}>
                <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS}/>

            </Box>
        </Box>
            {processing ? <Box sx={{width: '100%'}}><CircularProgress /></Box> : 
                <Button fullWidth="submit" variant="contained" onClick={handleSubmit}>Submit my Payment</Button>
            }
            { (succeeded) && <>
                <Alert sx={{width: '100%'}} icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Your order was successful!
                </Alert>
                </>
            }
            {
                (error) && <>
                <Alert sx={{width: '100%'}} icon={<CheckIcon fontSize="inherit" />} severity="error">
                    Your payment was unsuccessful. 
                </Alert>
                </>
            }
    </Box>
}

export default PaymentForm