import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material'
import { prepStaticFileUrl } from '../../tools';
import { TruncatedText } from './../base'
import { Assets } from './../../assets'

const RatingStars = Assets.RatingStars





export const ProductCard = ({ product }) => {

  return (
    <Card sx={{
        display: 'flex',
        flexDirection: 'column', 
        height: '100%',

    }}>
      <Box sx={{width: 300}}></Box>
      <CardMedia
        component="img"
        image={prepStaticFileUrl(product.base_image)}
        alt={product.name}
        sx={{

            borderRadius: 2,

        }}
      />
        <Box sx={{flex: 1}}></Box>
      <CardContent>
        <Typography gutterBottom variant="product-title" component="div">
          {product.name}
        </Typography>
        <Typography component="div" variant="body" color="text.secondary" sx={{mb: 2}}>
            <TruncatedText text={product.description} nlines={4} />
        </Typography>
        <RatingStars rating={product.rating} number_sold={product.number_sold}/>
        <Box sx={{mt: 2, display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
            <Typography variant="h6" component="div" color="text.primary">
                ${product.price}
            </Typography>
            <Button size="small" color="primary">
                Add to Cart
            </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
