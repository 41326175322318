import { Outlet } from 'react-router-dom';
import {useEffect, useState} from 'react'
import {
    Box
} from '@mui/material'
import Navbar from './../../components/navbar'
import { CategoryBar } from './../../components/navbar'
import { DataLoader } from './DataLoader'
import { useSelector } from 'react-redux'
function getDimensions() {
    const win = window
    const doc = document
    const docElem = doc.documentElement
    const body = doc.getElementsByTagName('body')[0]
    const x = win.innerWidth || docElem.clientWidth || body.clientWidth
    const y = win.innerHeight|| docElem.clientHeight|| body.clientHeight
    return {
        width: x, 
        height: y
    }
}


const Banner = (props) => {

    return <Box sx={{width: '100%',  position: 'relative', display: 'flex', justifyContent: 'center'}}>
        <Box sx={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            background: '#222', 
            color: '#eee',
            width: '100%',
            height: 30

        }}>{props.children}</Box>
    </Box> 
}
export const BasePage = props => {

    // set height of scroll container and handle sidbar toggle, if applicable. 
    const headerHeight = 160
    const [windowDims, setWindowDims] = useState(getDimensions())

    
    useEffect(() => {
        window.onresize = () => {
            setWindowDims(getDimensions())
        }

        return () => window.resize = null
    }, [])

    // initialize the data for the pages / cart / products / etc. 
    useEffect(() => {

    })
    
    return <>
    <DataLoader />
    <Box sx={theme => ({
        display: 'flex',
        flexDirection: 'column', 
        flex: 1, 
        height: '100%', 
        width: windowDims.width, 
        background: theme.palette.background.main
    })}>
        <Box sx={{height: headerHeight}}>
            <Banner >Register and save 25% on your order!</Banner>
            <Navbar />
            <CategoryBar />
        </Box>
        <Box sx={{
            height: windowDims.height - headerHeight,
            position: 'relative', 
            overflow: 'hidden',
            mr: 0, 
            mt: 0
        }}>
            <Box sx={{
                width: '100%', 
                height: '100%', 
                overflowY: 'auto'
            }}>
                <Outlet />
            </Box>
        </Box>
        </Box>
    </>
}

export default BasePage