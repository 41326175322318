import { Grid } from '@mui/material'
import { CartList } from './CartList'
import { PaymentForm } from './PaymentForm'
export const CheckoutPage = props => {
    return <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={4} sx={{maxWidth: 800}} >
            <CartList />
            <PaymentForm />
        </Grid>
    </Grid>
}
export default CheckoutPage