
const components = {
    MuiAppBar: {
        defaultProps: {
            elevation: 0
        }, 
        styleOverrides: {
            root: {
                padding: 10, 
                paddingLeft: 5
            }
        },
    },

    MuiButtonBase: {
        defaultProps: {
        disableRipple: true,
        },
    },
    MuiTextField: {
        defaultProps: {
            size: "small",
            InputLabelProps: {shrink: true}
        },
        styleOverrides: {
            root: {
                '& input[type=number]': {
                    '-moz-appearance': 'textfield',  // Firefox
                    '-webkit-appearance': 'none',    // Chrome, Safari, Edge, Opera
                    'margin': 0,
                },
                '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    'margin': 0,
                },
                '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    'margin': 0,
                },
            },
        },
    },
    MuiSelect: {
        defaultProps: {
            disableScrollLock: true,
            size: "small"
        }
    }
}


export default components