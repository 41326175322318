import { 
  BasePage, 
  HomePage,
  ProductDetailPage,
  CheckoutPage,
  SalesPage,
  ContactPage,
  AccountsPage,
  Sandbox,
  SignupPage,
  LoginPage,
  LogoutPage
} from './../pages/'


export const routes = [
    {
      path: '/',
      exact: true,
      element: <BasePage />,
      children: [
        {
          path: '/',
          exact: true,
          element: <HomePage />
        },
        {
          path: 'product-detail/:id/',
          exact: true, 
          element: <ProductDetailPage />
        },
        {
          path: 'checkout/',
          exact: true, 
          element: <CheckoutPage />
        },
        {
          path: 'sales/',
          exact: true, 
          element: <SalesPage />
        },
        {
          path: 'contact/',
          exact: true, 
          element: <ContactPage />
        },
        {
          path: 'accounts_rename_conflict_fjango_urls/',
          exact: true, 
          element: <AccountsPage />
        },
        {
          path: 'sandbox/',
          exact: true,
          element: <Sandbox />
        },
        {
          path: 'login/',
          exact: true,
          element: <LoginPage />
        },
        {
          path: 'signup/',
          exact: true,
          element: <SignupPage />
        },
        {
          path: 'logout/', 
          exact: true, 
          element: <LogoutPage />
        }
      ]
    }
]
export default routes