import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BaseForm, formErrorSX } from './BaseForm'
import { useAuthForm, rowSX } from './useAuthForm'
import { TextField } from './Fields'
import { API } from './../../tools'
import { useState, useEffect } from 'react'
import { useDispatcher, TYPES, useSelector } from './../../store/dispatcher'
export const LoginPage = () => {
    const dispatcher = useDispatcher()
    const {        
        values,
        setValues,
        onChange,
        errors,
        setErrors,
        resetForm,
        validate
    } = useAuthForm()
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const navigate = useNavigate()
    const [formError, setFormError] = useState('')

    const onSubmit = async () => {
        const response = await API.auth.login(Object.assign({}, values, {password: values.password1}))

        if(response.user) {
            dispatcher(TYPES.AUTH.UPDATE_USER, {user: response.user})
            navigate('/')
        } else {
            setFormError('Unable to create a user at this time')
        }
    }

    useEffect(() => {
        if(isAuthenticated) {
            navigate('/')
        }
    }, [isAuthenticated])


    return <Box sx={{mt: 2}}>
        <BaseForm>
            <Box sx={rowSX}>
                <Typography>Please login.</Typography>
            </Box>
            <Box sx={rowSX}>
                <TextField name="email" label="Email" value={values.email} error={errors.email} onChange={onChange}></TextField>
            </Box>
            <Box sx={rowSX}>
                <TextField name="password1" label="Password" value={values.password1} error={errors.password1} onChange={onChange} type="password"></TextField>
            </Box> 
            <Button variant="contained" fullWidth onClick={onSubmit}>Login</Button>
        </BaseForm>
    </Box>
}

export default LoginPage