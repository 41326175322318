
import { useForm } from './useForm'

export const rowSX = {
    display: 'flex',
    gap: 1,
    width: '100%',
    alignItems: 'center', 
    justifyContent: 'space-between',
    position: 'relative'
}

const initialValues =  {
    address1: '123 Fake Street', 
    address2: '',
    city: 'San Diego', 
    state: 'california', 
    zip: '93222',
    country: 'US',
    email: 'a@email.com',
    username: 'aconn', 
    password1: '!Pear4409',
    password2: '!Pear4409',
    first_name: 'first',
    last_name: 'last'
}

export function useAuthForm() {

    // Use the useForm hook to get values, errors, etc.
    const [values, setValues, handleInputChange, errors, setErrors, resetForm] = useForm(initialValues, true, validate);

    // Validation function now correctly uses values and errors from the useForm hook
    function validate(fieldValues = values) {
        let temp = { ...errors };

        if ('first_name' in fieldValues)
            temp.first_name = fieldValues.first_name ? '' : 'This field is required.';
        if ('last_name' in fieldValues)
            temp.last_name = fieldValues.last_name ? '' : 'This field is required.';
        if ('email' in fieldValues)
            temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email) ? '' : 'Email is not valid.';
        if ('password1' in fieldValues)
            temp.password1 = fieldValues.password1.length > 8 ? '' : 'Minimum 8 characters required.';
        if( 'password2' in fieldValues) {
            temp.password2 = temp.password1 === temp.password2 ? '' : "Passwords don't match"
        }
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === '');
    }

    // Return all the necessary values and functions from the custom hook
    return {
        values,
        setValues,
        onChange: handleInputChange,
        errors,
        setErrors,
        resetForm,
        validate
    };
}