import { Box, Typography } from '@mui/material'
import Rating0_0 from './../raw/ratings/rating0_0.png'
import Rating0_5 from './../raw/ratings/rating0_5.png'
import Rating1_0 from './../raw/ratings/rating1_0.png'
import Rating1_5 from './../raw/ratings/rating1_5.png'
import Rating2_0 from './../raw/ratings/rating2_0.png'
import Rating2_5 from './../raw/ratings/rating2_5.png'
import Rating3_0 from './../raw/ratings/rating3_0.png'
import Rating3_5 from './../raw/ratings/rating3_5.png'
import Rating4_0 from './../raw/ratings/rating4_0.png'
import Rating4_5 from './../raw/ratings/rating4_5.png'
import Rating5_0 from './../raw/ratings/rating5_0.png'


const ratingImage = (rating) => {
    if(!rating && rating !== 0) {
        return 
    }
    const d = (rating, src) => ({
        src,
        alt: `Rating (${rating.toFixed(1)} of 5)`
    })
    
    
    if(rating < 0.5) { return  d(rating, Rating0_0) }
    if(rating < 1.0) { return  d(rating, Rating0_5) }
    if(rating < 1.5) { return  d(rating, Rating1_0) }
    if(rating < 2.0) { return  d(rating, Rating1_5) }
    if(rating < 2.5) { return  d(rating, Rating2_0) }
    if(rating < 3.0) { return  d(rating, Rating2_5) }
    if(rating < 3.5) { return  d(rating, Rating3_0) }
    if(rating < 4.0) { return  d(rating, Rating3_5) }
    if(rating < 4.5) { return  d(rating, Rating4_0) }
    if(rating < 5.0) { return  d(rating, Rating4_5) }
    return d(rating, Rating5_0)

}
export const  RatingStars = props => {
    const {rating, number_sold=0} = props
    const img = ratingImage(parseFloat(rating))
    if(!rating) {
        return <Box></Box>
    } 
    
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'start',
        '& img': {
            width: 80
        }
    }}>
        <img className="rating-stars-image" src={img.src} alt={img.alt}></img>
        <Typography 
            variant="accent"
            color="textSecondary"
            sx={{fontSize: '.7rem', fontStyle: 'italic'}}>
                {img.alt}
            </Typography>
        <Typography 
            variant="body2"
            color="textSecondary"
            sx={{fontSize: '.7rem', fontStyle: 'italic', color: '#aaa'}}>
                {number_sold}+ sold
            </Typography>
    </Box>
}

export default RatingStars