import { useState, useEffect } from 'react' 
import { Box, Typography } from '@mui/material'
const width = 400
const height = 400

const src  = "https://ae-pic-a1.aliexpress-media.com/kf/S09e6ee55216b4d3fa22db8c9a3065cd45.jpg_200x200.jpg_.webp"



export const Sandbox  = props => {
  const [mouse, setMouse] = useState({x: 0, y: 0})
  const [rect, setRect] = useState({x: 0, y: 0, length: 200})

  const handleMove = (evt) => {

    const div = evt.currentTarget.getBoundingClientRect()
    let x = evt.clientX - div.left 
    let y = evt.clientY - div.top  
    // check for out of bounds
    x = Math.max(0, x)
    x = Math.min(x, width)
    y = Math.max(0, y)
    y = Math.min(y, height)
    setRect({
      ...rect, 
      x: x - rect.length / 2,
      y: y - rect.length / 2
    })

  }
  const handleWheel = (evt) => {
    const deltaL = 3
    const direction = evt.deltaY > 0 ? 1 : -1
    setRect({
        ...rect, 
        length: Math.min(rect.length + direction * deltaL, width),
        x: rect.x - .5 * direction * deltaL, 
        y: rect.y - .5 * direction * deltaL
    })

  }

  const mag = width / rect.length 
  const l = rect.length 
  const L = width
  const magImageWidth = width * mag
  const magImageHeight = height * mag 

  // 1 - transform the current position to a scale of 1-100 to shift the image by
  let cxbounds = [(rect.length/2) / width, (width - (rect.length/2)) / width]
  let cx = (rect.x + rect.length / 2) / width
  cx = (cx - cxbounds[0]) / (cxbounds[1] - cxbounds[0])
  cx = `${-100 * cx}%`

  let cybounds = [(rect.length/2) / height, (height - (rect.length/2)) / height]
  let cy = (rect.y + rect.length / 2) / height
  cy = (cy - cybounds[0]) / (cybounds[1] - cybounds[0])
  cy = `${-100 * cy}%`


  return <Box sx={{display: 'flex'}}>
    <Box 
      onWheel={handleWheel}
      onMouseMove={handleMove}
      sx={{
        width, height, 
        position: 'relative', 
        background: 'rgba(0,0,255,.3)',
        '& .mainImage': {
          width,
          height,
          objectFit: 'contain'
        }
      }}
    >

      <img src={src} className="mainImage"/>
      <Box sx={{
        width: rect.length, 
        height: rect.length, 
        position: 'absolute', 
        left: rect.x, 
        top: rect.y,
        pointerEvents: 'none',
        background: 'rgba(0,0,255,.2)'
      }}>
        <Box sx={{position: 'absolute', width: 10, height: 10, borderRadius: '50%', left: rect.length/2 - 5, top: rect.length/2-5, background: 'white', opacity: 1}}></Box>
      </Box>
    </Box>
    <Box>
      <Box className="magnifiedImage" sx={{position: 'relative', width, height, border: '1px solid blue', overflow: 'hidden'}}>
        <img 
          src={src} 
          style={{
            position: 'absolute',
            width: magImageWidth, 
            height: magImageHeight,
            left: cx,
            top: cy
            // top: cy
          }}
        />
        <Box sx={{width: rect.length, height: rect.length, background: 'rgba(255, 0,0,.1)', position: 'absolute', left: (width/2) - rect.length/2, top: (height/2) - rect.length/2}}>
        
        </Box>
      </Box>

    </Box>
    <Box>
        <Typography>{cx}</Typography>
        <Typography>{cy}</Typography>
      </Box>
  </Box>

}