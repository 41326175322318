

import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { BaseForm, formErrorSX } from './BaseForm'
import { useAuthForm, rowSX } from './useAuthForm'
import { TextField } from './Fields'
import { StateSelect, CountrySelect } from './../../components/store'
import { API } from './../../tools'
import { useState } from 'react'


export const SignupPage = () => {
    const {        
        values,
        setValues,
        onChange,
        errors,
        setErrors,
        resetForm,
        validate
    } = useAuthForm()
    const navigate = useNavigate()
    const [formError, setFormError] = useState('')
    const onSubmit = async () => {
        const response = await API.auth.signup(values)
        if(response == 204) {
            navigate('/login/')
        } else {
            setFormError('Unable to create a user at this time')
        }
    }
    return <Box sx={{mt: 2, width: '100%'}}>
        <BaseForm>
            <Box sx={rowSX}>
                <Typography>Fill in the following to create your account.</Typography>
            </Box>
            <Box sx={rowSX}>
                <TextField name="username" label="Username" value={values.username} error={errors.username} onChange={onChange} ></TextField>
                <TextField name="email" label="Email" value={values.email} error={errors.email} onChange={onChange}></TextField>
            </Box>
            <TextField name="password1" label="Password" value={values.password1} error={errors.password} onChange={onChange} type="password"></TextField>
            <TextField name="password2" label="Verify Password" value={values.password2} error={errors.password2} onChange={onChange} type="password"></TextField>
            <Box sx={rowSX}>
                <Typography>Optional information to for ease of checkout</Typography>
            </Box>
            <Box sx={rowSX}>
                <TextField name="first_name" label="First Name" value={values.first_name} errors={errors.first_name} onChange={onChange}></TextField>
                <TextField name="last_name" label="Last Name" value={values.last_name} errors={errors.last_name} onChange={onChange}></TextField>
            </Box>
            <TextField name="address1" label="Address" value={values.address1} errors={errors.address1} onChange={onChange}></TextField>
            <TextField name="address2" label="Address 2" value={values.address2} errors={errors.address2} onChange={onChange} placeholder="Apt #, suite, unit, etc."></TextField>
            <Box sx={rowSX}>
                <TextField name="city" label="City" value={values.city} errors={errors.city} onChange={onChange}></TextField>
                <TextField name="zip" label="Zip" value={values.zip} errors={errors.zip} onChange={onChange} type="number" ></TextField>
            </Box>
            <Box sx={rowSX}>
                <Box sx={rowSX}><StateSelect name="state" label="State" value={values.state} onChange={onChange}></StateSelect></Box>
                <Box sx={rowSX}><CountrySelect name="country" label="Country" onChange={onChange} value={values.country} error={errors.country}/></Box>
            </Box>
            
            <Box sx={{width: '100%'}}>
                <Button fullWidth variant="contained" onClick={onSubmit} sx={{mb: 1}}>Submit</Button>
                { formError.length > 0 && <>
                    <Box sx={formErrorSX}>{formError}</Box>
                </>}

            </Box>
        </BaseForm>
    
    </Box>
}