import { Grid, Box } from '@mui/material'

export const formErrorSX = {
    color: t => t.palette.error,
    width: '100%',
    mt: 0,
    fontSize: '.8rem'
}
export const BaseForm = props => {
    const { formSX={} } = props
    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={4} >
            <Box 
                component="form"
                sx={{
                    maxWidth: 800,
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center',
                    p: 2,
                    gap: 4,
                    ...formSX
                }}>
                    {props.children}
                
            </Box>
            </Grid>
        </Grid>
    )
}   


export default BaseForm