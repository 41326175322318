import CircularProgressMUI from '@mui/material/CircularProgress';
import { Box } from '@mui/material'

export const CircularProgress = ({n=1, sx={}}) => {
    const items = [...Array(n).keys()].map(di => di)
    return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', 'width': '100%', ...sx}}>
        {
            items.map((i, ii) => (<CircularProgressMUI key={ii}/>))
        }
    </Box>
}
export default CircularProgress