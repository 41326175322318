import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import {useDispatcher, TYPES, useSelector} from './../../store/dispatcher'
import { API } from './../../tools'

export const LogoutPage = props => {

    const dispatcher = useDispatcher()
    const navigate = useNavigate()
    useEffect(() => {   
        const anon = async () => {
            const data = await API.auth.logout()
            // localStorage.setItem('access_token', null)
            // localStorage.setItem('refresh_token', null)
            dispatcher(TYPES.AUTH.UPDATE_USER, {user: null})
            navigate('/')
        }
        anon()
    },[])

    return <Box></Box>
}

export default LogoutPage

