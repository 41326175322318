
import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Box } from '@mui/material';
import {loadStripe} from "@stripe/stripe-js/pure";
import {Elements} from '@stripe/react-stripe-js';
import { ThemeProvider } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import { HomePage } from './pages'
import useTheme from './theme'
import './App.css'
import { getStripe } from './tools/'
const stripePromise = getStripe()
const storename =  process.env.REACT_APP_STORE
function App() {
  const [mode, setMode] = useState('light')
  const content = useRoutes(routes)
  const theme = useTheme(storename)


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <Helmet>
            <title>Overstock GR</title>
          </Helmet>
          <Elements stripe={stripePromise}>
            <Box className="main-wrapper" sx={{
              width: '100%', 
              height: '100%', 
              position: 'relative', 
              background: t => t.palette.primary.background, 
              
              // // style any raw components the same as mui
              // '&, & *': {fontFamily: t => t.typography.body1.fontFamily},
              // '& h1': {fontFamily: t => t.typography.h1.fontFamily},
              // '& h2': {fontFamily: t => t.typography.h2.fontFamily},
              // '& h3': {fontFamily: t => t.typography.h3.fontFamily},
              // '& h4': {fontFamily: t => t.typography.h4.fontFamily},
              // '& h5': {fontFamily: t => t.typography.h5.fontFamily},
              // '& h6': {fontFamily: t => t.typography.h6.fontFamily},
              // '& span': {fontFamily: t => t.typography.body1.fontFamily},
              // '& div': {fontFamily: t => t.typography.body1.fontFamily}
            }}>

              { content }
            </Box>
          </Elements>

      </ThemeProvider>

    </div>
  );
}

export default App;
