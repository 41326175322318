import { Box } from '@mui/material'
import LogoPng from './logo.png'

export const Logo = props => {
    const { 
        width="auto", 
        height=60
    } = props
    

    return <Box sx={{
        width, 
        height,
        position: 'relative', 
        '& img': {
            height: '100%', 
            width: 'auto', 
            objectFit: 'contain'
        }
    }}>
        <Box component="img" src={LogoPng}/>
    </Box>
}

export default Logo