import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const TruncatedText = ({ text, nlines, handler }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = handler ? handler : () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{m: 0, p: 0}}>
      <Typography
        variant="body2"
        style={{
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'none' : nlines,
          WebkitBoxOrient: 'vertical',
          overflow: isExpanded ? 'visible' : 'hidden',
          textOverflow: isExpanded ? 'initial' : 'ellipsis',
        }}
      >
        {text}
      </Typography>
      <Button sx={{ml: 0, m: 0, p: 0, paddingLeft: '0 !important'}} size="small" color="primary" onClick={handleToggle}>
        {isExpanded ? 'See Less' : '(See More)'}
      </Button>
    </Box>
  );
};

export default TruncatedText;