import {
    Box
} from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import DiscountIcon from '@mui/icons-material/Discount';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Assets } from './../../assets'

const { Logo } = Assets

const icons = {
    'cart': <ShoppingCartIcon />, 
    'account': <PersonIcon ></PersonIcon>, 
    'contact': <ContactMailIcon />, 
    'discount': <DiscountIcon />
}



const rightNavItems = [
    { key: 'discount', name: 'Sales', to: "/cart", icon: "discount"},
    { key: 'contact', name: 'Contact', to: "/cart", icon: "contact"},
    { key: 'account', name: 'Login', to: "/login", icon: "account"},
    { key: 'cart', name: 'Cart', to: "/cart", icon: "cart"}
]
const NavbarItem = props => {
    const {name, icon, to } = props
    return <NavbarLink to={to}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center',
            p: 1,
            fontSize: '.9rem',
            position: 'relative',
            width: 100,
            borderRadius: 4,
            '&:hover': {
                background: 'rgba(0,0,0,.2)'
            }
        }}>
            { props.children }
        </Box>
    </NavbarLink> 
    
}

const NavbarLink = props => {
    return <Link 
        style={{
            textDecoration: 'none', 
            color: 'inherit',
        }}
        {...props}
    >
        { props.children }
    </Link>
}

const CartOrnament = () => {
    const count = useSelector(state => state.store.cart.count ? state.store.cart.count : 0)
    return <Box sx={{
        width: 25,
        height: 25,
        background: 'red', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        position: 'absolute',
        color: 'white',
        top: 0, 
        right: 10,
        fontWeight: 'bold'

    }}>
        { count }
    </Box>
}



export const Navbar = props => {
    const {isAuthenticated} = useSelector(state => state.auth)
    return <>
        <Box sx={{
            width: '100%', 
            display: 'flex',
            alignItems: 'center', 
            justifyContent: 'space-between',
            p: 1
        }}>
            <Box className="left-side"><NavbarLink to="/"><Logo height={40}/></NavbarLink></Box> 
            <Box className="middle"></Box> 
            <Box className="right-side"
                sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    gap: 2
                }}
            >
                <NavbarItem to="/sales/">
                    <DiscountIcon />
                    Sales
                </NavbarItem>
                <NavbarItem to="/contact/">
                    <ContactMailIcon />
                    Contact
                </NavbarItem>
                <NavbarItem to={isAuthenticated ? "/logout/" : "/login/"}>
                    <PersonIcon ></PersonIcon>
                    { isAuthenticated ? 'Logout' : 'Login' }
                </NavbarItem>
                <NavbarItem to="checkout/">
                    <ShoppingCartIcon />
                    <CartOrnament />
                    Checkout
                </NavbarItem>
            </Box> 
        </Box>
    </> 
}


export const CategoryBar = props => {
    const { categories } = useSelector(state => state.store)

    const navigate = useNavigate()
    const onClick = (category) => {
        navigate(`/?category=${category}`)
    }
    return <Box sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center', 
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '1rem',
        gap: 1,


        '& *': {fontFamily: t => t.typography.navbar.fontFamily}
    }}>
        {
            categories.map(ci => {
                return <Box 
                    className={ci.active ? 'active' : ''} 
                    key={ci.name}
                    onClick={evt => onClick(ci.name)} 
                    sx={{
                        p: 1,
                        '&.active': {
                            color: t => t.palette.primary.main
                        }       
                    }}
                >{ci.label}</Box>
            })
        }
    </Box>
}


export default Navbar