/*
Return a 'TYPE -> dispatch function' map to create a single interace to dispatch actions.

src/dispatcher/ <- all components submit dispatches through here 

METHOD CALL:
dispatcher(dispatch, type, payload)

REQUIREMENTS: the string values need to be prepended with slice name 
examples
TYPES.TREE.LOAD_TREE -> "TREE_LOAD_TREE' (prepended with TREE to avoid name conflicts

const TREE_TYPES = {
    LOAD_TREE: TREE_LOAD_TREE
}
*/
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import storeReducer, { STORE_SLICE, STORE_TYPES } from './../../storefront/'
import authReducer, { AUTH_TYPES, AUTH_SLICE } from './../../components/auth'
const SLICE_MAP = {
    ...STORE_SLICE,
    ...AUTH_SLICE
}

const dispatcher = (dispatch, type, payload) => {
    dispatch(SLICE_MAP[type](payload))
}

export const useDispatcher = () => {
    const dispatch = useDispatch()
    return (type, payload) => dispatcher(dispatch, type, payload)
}

export const TYPES = {
    STORE: STORE_TYPES,
    AUTH: AUTH_TYPES
}


export { useSelector } // cuts an extra import if needed
export default {
    store: storeReducer,
    auth: authReducer
}