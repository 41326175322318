
import { useState, useEffect } from 'react'


export function useForm(initialValues, validateOnChange=false, validate) {

    const [values, setValues] = useState(initialValues)
    const [errors, setErrors] = useState({})
  
    // update values on form on user interaction
    const handleInputChange = evt => {

        if('preventDefault' in evt) {
            evt.preventDefault()
        }
        const {name, value} = evt.target

        setValues({
            ...values, 
            [name]: value
        })
        if(validateOnChange) {
            validate({[name]:value})
        }
    }

    // reset form
    const resetForm = () => {
        setValues(initialValues)
        setErrors({})
    }

    return [
        values,
        setValues,
        handleInputChange,
        errors, 
        setErrors,
        resetForm
    ]
    
}
