import {
    Box
} from '@mui/material'
import { prepStaticFileUrl } from './../../tools'

const Thumbnail = (props) => {
    const {si, ...other} = props

    const {url, vid, active } = si

    const prepped_src = prepStaticFileUrl(url)
    return <Box 

        sx={{
            position: 'relative', 
            overflow: 'hidden',
            border: '2px solid rgba(0,0,0,.05)',
            cursor: 'pointer',
            '& .active': {
                border: '2px solid blue'
            }
    }}
    {...other}
    >
        <img 
        className={`${active ? 'active' : ''}`}
        src={prepped_src}
        alt="Product Variant"
        style={{
            borderRadius: 2,
            objectFit: 'cover',
            height: 60, 
            width: 60
        }}
        >

        </img>
    </Box>
}
export const Thumbnails = props => {
    const {slides, flexDirection, length, onClick=null, sx={}} = props
    const width = flexDirection === 'row' ? length : 80
    const height = flexDirection === 'column' ? length : 70
    const overflowY = flexDirection == 'row' ? 'hidden' : 'auto'
    const overflowX = flexDirection == 'column' ? 'hidden' : 'auto'
    return <Box sx={{
        display: 'flex', 
        flexDirection, 
        alignItems: 'center', 
        height, 
        width, 
        overflowX,
        overflowY,
        p: 1,
        ...sx 
    }}>
        {slides.map((si, i) => {
            return <Box key={`${si.vid}${i}`}>
                <Thumbnail  si={si} onClick={evt => onClick(evt, si)}></Thumbnail>
            </Box>
        })}
    </Box>
}

export default Thumbnails