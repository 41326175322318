import { useState, useEffect } from 'react' 
import { useDispatcher, TYPES, useSelector } from './../../store/dispatcher'
import { Box, Typography, Alert } from '@mui/material'
import { Assets } from './../../assets'
import { CartButtons } from './../../components/store'
import { Thumbnails } from './../../components/base'
import MuiCheckIcon from '@mui/icons-material/CheckCircle';
import useProductID from './useProductID'
import { API, prepStaticFileUrl } from './../../tools'
const RatingStars = Assets.RatingStars

const CheckIcon = ({text}) => {

    return <Box sx={{
        display: 'flex', 
        alignItems: 'center', 
        fontSize: '.7rem'
    }}>
        <MuiCheckIcon sx={{color: t => t.palette.text.success, fontSize: '.75rem'}} />
        { text }
    </Box>
}
const ShippingDecoration = () => {
    return <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', flexWrap: 'nowrap'}}>
        <CheckIcon text="Free Shipping"/>
        <CheckIcon text="Easy Returns"/>
    </Box>
}

const ProductOption = ({option, onClick}) => {

    const active_product_options_pks = useSelector(state => state.store.product.options.map(oi => oi))
    let is_active = false 
    active_product_options_pks.forEach(oi => {
        if(oi.group.toLowerCase() === option.group.toLowerCase() && oi.pk === option.pk) {
            is_active = true
        }
    })
        


    return <Box 
        className={is_active ? "active" : ""}
        onClick={() => onClick(option)}
        sx={{
        fontSize: '.8rem', 
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'column', 
        alignItems: 'center', 
        textAlign: 'center', 
        textWrap: 'nowrap',
        border: '1px solid rgba(0,0,0,.1)',
        borderRadius: 1,
        transition: '.75s',
        cursor: 'pointer',
        height: 40,
        p: 1,
        '&:hover': {
            background: 'rgba(0,0,0,.1)',
            transition: '.75s'
        },
        '&.active': {
            background: t => t.palette.primary.xlight
        },
        '& img': {
            width: 10, 
            height: 10
        }
    }}>
        {option.name}
    </Box>
}
const ProductOptionRow = ({group, selections, onClick}) => {
    return <Box sx={{display: 'flex', justifyContent: 'start', gap: 2, alignItems: 'center', mb: 1}}>
        <Typography variant="smallTitle" sx={{width: 65}}>{group}</Typography>
        <Box sx={{position: 'relative', width: '100%', overflowX: 'hidden'}}>
            <Box sx={{overflowX: 'auto', width: '100%'}}>
                <Box sx={{
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space', 
                    gap: 1, 
                    position: 'relative', 
                    width: '100%'
                }}>
                        {selections.map(si => {
                            return <ProductOption key={si.pk} option={si} onClick={onClick}></ProductOption>
                        })}


                </Box>

            </Box>
        </Box>
    </Box>
}
const ProductOptions = ({options, onClick}) => {
    const okeys = Object.keys(options)
    const width = '100%'
    // when they click onj the option we should update the product or display an error message that the option doesnt exist
    return <Box sx={{width}}>

            {okeys.map((oi, i) => {
                return <ProductOptionRow key={oi} group={oi} selections={options[oi]} onClick={onClick}/>
            })}

    </Box>
}

export const ProductInfo = props => {
    const { onThumbnailClick, vslides } = props
    const [optionSelectError, setOptionSelectError] = useState('')
    
    const dispatcher = useDispatcher()
    const product = useSelector(state => state.store.product)
    const count = useSelector(state => {
        const product_pk = state.store.product.pk
        const item = state.store.cart.items?.find(item => item.product.pk === product_pk)
        return item ? item.quantity : 0
    })
    const product_pk = useProductID()
    const variant_slides = useSelector(state => state.store.thumbnails.variant_slides)
    
    // handle when they select the product option 
    const handleOptionClick = async option => {

        const option_pks = product.options.map(oi => oi.group === option.group ? option.pk : oi.pk)
        const baseproduct_pk = product.baseproduct_pk 

        const new_product = await API.cart.get_product_from_options(baseproduct_pk, option_pks)
        if(new_product && new_product.success) {    
            dispatcher(TYPES.STORE.UPDATE_PRODUCT, {product: new_product.product})
        } else {
            setOptionSelectError("That combination doesn't exist or is out of stock")
        }



        setTimeout(() => {
            setOptionSelectError('')
        }, 2000)
    }



    if(!product || !Object.keys(product).length) {
        return <>No Data</>
    }
    return <Box sx={{maxWidth: 400, display: 'flex', flexDirection: 'column', height: '100%'}}>
        <Box sx={{mt: 2}}>
            <CartButtons count={count} product_pk={product_pk}></CartButtons>
        </Box>
        <Box sx={{mt:2}}>
            <Typography variant="productTitle">{product.name}</Typography>
        </Box>

        <Box sx={{mt:2}}>
            <Typography variant="productDescription">{product.description}</Typography>
        </Box>
        <Box sx={{mt:1, display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
            <RatingStars rating={product.rating} number_sold={product.number_sold}></RatingStars>
            <ShippingDecoration />
        </Box>




        <Box sx={{mt: 'auto', display: 'flex', alignItems: 'end', justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <Box sx={{mt:2}}>
                <Typography variant="productPrice">${product.price}</Typography>
            </Box>
            <Box >

            </Box>

        </Box>

        <Box sx={{position: 'relative', mt: 2 }}>
            <ProductOptions options={product.available_options} onClick={handleOptionClick}/>
            { optionSelectError.length > 0 && <Alert sx={{position: 'absolute', right: 0, top: 50, textAlign: 'end', border: 'red 1px solid', zIndex: 100, p: .5}} severity="error" size="small">{optionSelectError}</Alert>}
        </Box>
        <Box sx={{position: 'relative', mt: 2 }}>

                <Thumbnails 
                    slides={vslides} 
                    flexDirection="row" 
                    length={'100%'}
                    onClick={onThumbnailClick}
                ></Thumbnails>
        </Box>
        
        
    </Box>
}