import { useState, useEffect } from 'react' 
import { useDispatcher, TYPES } from './../../store/dispatcher'
import { API } from './../../tools'


export const DataLoader = props => {
    const [trigger, setTrigger] = useState(true)
    const dispatcher = useDispatcher()
    useEffect(() => {
        // look for the cartnumber - send to server for initial request
        // server should return, cart, customer info, and then available 
        // products for initialization

        const anon = async () => {
            const token = await API.auth.accessTokenOrNone()
            const data = await API.pages.homepage()
            console.log(data)
            localStorage.setItem('cartnumber', data.cart.cartnumber)
            dispatcher(TYPES.STORE.UPDATE_PRODUCTS, {products: data.products})
            dispatcher(TYPES.STORE.UPDATE_CART, {cart: data.cart})
            dispatcher(TYPES.AUTH.UPDATE_AUTH_STATUS, {isAuthenticated: token ? true : false})
            dispatcher(TYPES.STORE.UPDATE_CATEGORIES, {categories: data.categories})
        }
        if(trigger) {
            anon()
            setTrigger(false)
        }

    }, [trigger])
    return <>

    </>
}

export default DataLoader;