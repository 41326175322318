import { useTheme } from '@mui/material';
const header = 'Fjalla One'
const body = 'Libre Baskerville'
const accent = 'Indie Flower'
const typography = {
  fontFamily: [
    'Fjalla One', 
    'Libre Baskerville',
    'Cabin',
    'Noto Serif',
    'Indie Flower',
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.25,
    fontFamily: header
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.25,
    fontFamily: header
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: 1.25,
    fontFamily: header
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.25,
    fontFamily: header
  },
  h5: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: 1.25,
    fontFamily: header
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.25,
    fontFamily: header
  },
  body1: {
    fontSize: '1rem',
    fontFamily: body
  },
  small: {
    fontSize: '.9rem',
    fontFamily: body
  },
  button: {
    textTransform: "none",
  },
  navbar: {
    fontFamily: header
  },
  accent: {
    fontFamily: accent
  },
  // my variants
  productTitle: {
    fontWeight: 'bold',
    fontFamily: header,
    fontSize: '1.3rem'
  },
  productTitleSmall: {
    fontWeight: 'bold',
    fontFamily: header,
    fontSize: '1.0rem'
  },
  productDescription: {
    fontFamily: body,
    display: 'block',
    fontSize: '.8rem',
    color: '#444',
    lineHeight: 1.2,
    margin: 0,
    padding: 0

  },
  smallTitle: {
    fontWeight: 'bold', 
    fontSize: '1.1rem',
    fontStyle: 'italic'
  },
  productPrice: {}


};

export default typography;