import { Box, TextField as TextFieldMui } from '@mui/material'
import { rowSX } from './../auth/useAuthForm'


export const TextField = ({name, value, onChange, label, error, placeholder="", type="text", ...other}) => {

    return (
        <Box sx={rowSX}>
            <TextFieldMui
                size="small"
                name={name}
                value={value}
                onChange={onChange}
                label={label}
                placeholder={placeholder}
                type={type}
                fullWidth
                {...other}
            ></TextFieldMui>
            <Error msg={error}/>
        </Box>
    )
}

const Error = ({msg, sx={}}) => {
    if(!msg) { return <></> }
    return <Box 
        component="span" 
        sx={{
            color: theme => theme.palette.error.main,
            position: 'absolute',
            left: theme => theme.spacing(1), 
            bottom: '-1.1rem',
            fontSize: '.8rem',
            ...sx
        }}
    >
            {msg}
    </Box> 
}
