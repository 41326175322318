import { Box, IconButton } from '@mui/material'

import AddIcon from '@mui/icons-material/Add';
import SubtractIcon from '@mui/icons-material/Remove';
import RemoveIcon from '@mui/icons-material/Cancel';

export const AddButton = ({onClick}) => {
    return <IconButton size="small" aria-label="add-single-item" onClick={onClick}>
        <AddIcon />
    </IconButton>
}

export const SubtractButton = ({onClick}) => {
    return <IconButton size="small" aria-label="remove-single-item" onClick={onClick}>
        <SubtractIcon />
    </IconButton>
}

export const RemoveButton = ({onClick}) => {
    return <IconButton size="small" aria-label="remove-item" onClick={onClick} sx={{color: t => 'red'}}>
        <RemoveIcon />
    </IconButton>
}