import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector, TYPES } from './../../store/dispatcher'
import { CartButtonsReversed } from './../../components/store'
import { prepStaticFileUrl } from '../../tools'


const ExtraRow = (props) => {

    return <Box sx={{borderTop: '1px solid rgba(0,0,0,.1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        {props.children}
    </Box>
}
const CartItem = ({item}) => {
    return <Box sx={{
        display: 'flex', 
        gap: 2, 
        p: 2, 
        pl: 0,
        pr: 0,
        position: 'relative', 
        alignItems: 'center', 
        justifyConent: 'space-between',
        width: '100%',
        borderTop: '1px solid rgba(0,0,0,.1)'
    }}>
        <Box sx={{'& img': {width: 65, height: 'auto', borderRadius: 3}}}><img src={prepStaticFileUrl(item.product.base_image)} alt={item.product.name}></img></Box>
        <Box sx={{display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', flexDirection: 'column'}}>
            <Typography variant="productTitleSmall">{item.product.name}</Typography>
        </Box>

        <Box sx={{flex: 1, textAlign: 'right', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box sx={{width: '100%', textWrap: 'nowrap'}}>{item.quantity} * ${item.product.price} = ${item.subtotal}</Box>

        </Box>
        <Box sx={{position: 'absolute', bottom: 0, right: 0}}>
                <CartButtonsReversed product_pk={item.product.pk} count={item.quantity} withRemove={true} alignItems="start"/>
        </Box>
    </Box>
}
export const CartList = () => {

    const cart = useSelector(state => state.store.cart)
    


    if(!cart || !Object.keys(cart).length) {
        return <h1>no data</h1>
    }

    return <Box sx={{}}>
        {
            cart.items.map((item, i) => {
                return <CartItem key={item.pk} item={item} />
            })
        }
        <ExtraRow>
            <Typography>Promotions and Coupons</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>
        <ExtraRow>
            <Typography>Shipping and Handling</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>
        <ExtraRow>
            <Typography>Sub Total</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>
        <ExtraRow>
            <Typography>Taxes</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>
        <hr />
        <ExtraRow>
            <Typography>Total</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>

    </Box>
}
