
import { fetcher } from './fetcher'
import { auth } from './auth'
///////////////////////////////////////////////////
// Production functions
///////////////////////////////////////////////////
function homepage() {
    return fetcher('store/homepage-data/', {})
        .then(data => data.json())
}

function product_detail_page(product_pk) {
    return fetcher(`store/product-detail/${product_pk}/`, {})
        .then(data => data.json())
}

function update_cart(product_pk, action) {
    const cartnumber = localStorage.getItem('cartnumber')
    return fetcher(`store/update-cart/`, {body: {product_pk, action, cartnumber}})
        .then(data => data.json())
}

function create_payment_intent() {
    return fetcher(`store/create-payment-intent/`, {})
        .then(data => data.json())
}

function get_product(vid) {
    return fetcher('store/get-product/', {body: {...vid}})
        .then(data => data.json())
}
function get_product_from_options(baseproduct_pk, option_pks) {
    return fetcher('store/get-product-from-options/', {
        body: {
            baseproduct_pk, 
            option_pks
        }
    })
    .then(data => data.json())
}

function contact({email, feedback}) {

    return fetcher('/store/contact/', {
        body: {
            email, 
            feedback, 
            store: 'sot'
        }
    })
    .then(data => data.json())
    
}
export const API = {
    pages: {
        homepage: homepage,
        product_detail_page: product_detail_page,
        contact
    },
    cart: {
        update_cart,
        create_payment_intent,
        get_product,
        get_product_from_options
    },
    auth
}

export default API